/*================================================
Menu CSS
=================================================*/
#topnav {
	position: fixed;
	right: 0;
	left: 0;
	top: 0;
	z-index: 1030;
	background-color: transparent;
	border: 0;
	transition: all 0.5s ease;
	.logo {
		float: left;
		color: $dark !important;
	}
	img {
		max-height: 50px;
	}
	.navbar-toggle {
		border: 0;
		position: relative;
		width: 60px;
		padding: 0;
		margin: 0;
		cursor: pointer;
		.lines {
			width: 25px;
			display: block;
			position: relative;
			margin: 32px 20px 24px;
			height: 18px;
		}
		span {
			height: 2px;
			width: 100%;
			background-color: $dark;
			display: block;
			margin-bottom: 5px;
			transition: transform 0.5s ease;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.buy-button {
		float: right;
		line-height: 68px;
		padding: 3px 0;
	}
	.navbar-toggle {
		&:hover,
		&:focus,
		.navigation-menu > li > a:hover,
		&:focus {
			background-color: transparent;
		}
	}

	.navigation-menu {
		list-style: none;
		margin: 0;
		padding: 0;
		> li {
			float: left;
			display: block;
			position: relative;
			margin: 0 10px;

			> a {
				display: block;
				color: $dark;
				font-size: 13px;
				background-color: transparent !important;
				font-weight: 700;
				letter-spacing: 1px;
				line-height: 24px;
				text-transform: uppercase;
				transition: all 0.5s;
				font-family: $font-family-base;
				padding-left: 15px;
				padding-right: 15px;
				&:hover,
				&:active {
					color: $primary;
				}
			}
		}
	}
	.menu-extras {
		float: right;
	}

	&.nav-sticky {
		background: $white;
		box-shadow: $shadow;
	}
}
.logo {
	font-weight: 700;
	font-size: 24px;
	padding: 3px 0;
	letter-spacing: 1px;
	line-height: 68px;
}

@media (min-width: 1200px) {
	#topnav {
		.navigation-menu {
			display: flex;
			justify-content: center;
			> li {
				> a {
					padding-top: 25px;
					padding-bottom: 25px;
					min-height: 62px;
				}
				&:hover {
					> .menu-arrow {
						border-color: $primary;
					}
				}
				&:hover > a,
				&.active > a {
					color: $primary !important;
				}
			}
		}
		.navbar-toggle {
			display: none;
		}
		#navigation {
			display: block !important;
		}
		&.scroll {
			top: 0;
			.navigation-menu {
				> li {
					> a {
						padding-top: 20px;
						padding-bottom: 20px;
					}
				}
			}
		}
	}
}

@media (max-width: 1200px) {
	#topnav {
		background-color: $white;
		box-shadow: $shadow;
		min-height: 74px;
		.container {
			width: auto;
		}
		.navigation-menu {
			float: none;
			max-height: 400px;
			> li {
				float: none;
				> a {
					color: $dark;
					padding: 10px 20px;
					&:after {
						position: absolute;
						right: 15px;
					}
				}
			}
		}
		.navigation-menu > li > a:hover {
			color: $primary;
		}
		.menu-extras .menu-item {
			border-color: $muted;
		}
	}

	#navigation {
		position: absolute;
		top: 74px;
		left: 0;
		width: 100%;
		display: none;
		height: auto;
		padding-bottom: 0;
		overflow: auto;
		border-top: 1px solid darken($light, 2%);
		border-bottom: 1px solid darken($light, 2%);
		background-color: $white;
		&.open {
			display: block;
			overflow-y: auto;
		}
	}
}

@media (min-width: 768px) {
	.navbar-toggle {
		display: block;
	}
}

@media (max-width: 375px) {
	#topnav {
		.buy-button {
			display: none;
		}
		.navbar-toggle {
			.lines {
				margin-right: 0 !important;
			}
		}
	}
}
