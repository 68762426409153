/*================================================
Components CSS (Shared Components)
=================================================*/
@each $name, $value in $colors {
	.btn-#{$name} {
		background-color: #{$value} !important;
		border: 1px solid #{$value} !important;
		color: $white !important;
		box-shadow: 0 3px 5px 0 rgba($value, 0.3);
		&:hover,
		&:focus,
		&:active,
		&.active,
		&.focus {
			background-color: darken($value, 10%) !important;
			border-color: darken($value, 10%) !important;
			color: $white !important;
		}
	}
}
.btn {
	padding: 8px 20px;
	outline: none;
	text-decoration: none;
	font-size: 16px;
	letter-spacing: 0.5px;
	transition: all 0.3s;
	font-weight: 600;
	border-radius: 10px;
	&:focus {
		box-shadow: none !important;
	}
}

button:not(:disabled) {
	outline: none;
}

.shadow {
	box-shadow: $shadow !important;
}

@each $name, $value in $colors {
	.alert-#{$name} {
		background-color: lighten($value, 5%);
		color: $white;
		border-color: $value;
		.alert-link {
			color: darken($value, 30%);
		}
	}
}
.alert {
	padding: 8px 15px;
	border-radius: 10px;
	&.alert-light {
		background-color: $light;
		color: $dark;
		border-color: $gray-300;
	}
	.close {
		top: -5px;
		right: -10px;
	}
}

.form-group {
	margin-bottom: 20px;
	label {
		font-size: 14px;
		font-weight: 700;
	}
	.form-control {
		box-shadow: none;
		border: 1px solid $gray-300;
		height: 42px;
		font-size: 13px;
		border-radius: 10px;
		transition: all 0.5s ease;
		&:focus,
		&.active {
			border-color: $primary;
		}
	}
	textarea {
		height: 120px !important;
		&.form-control {
			line-height: 25px;
		}
	}
}
.form-control:disabled,
.form-control[readonly] {
	background-color: transparent;
	opacity: 1;
}
.form-control:focus {
	box-shadow: none;
	border-color: $primary;
}

.display-1 {
	font-size: 56px !important;
}

@media (max-width: 768px) {
	.display-1 {
		font-size: 36px !important;
	}
}
