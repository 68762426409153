/*================================================
Custom Bootstrap CSS
=================================================*/
@each $name, $value in $colors {
	.bg-#{$name} {
		background-color: #{$value} !important;
	}
	.text-#{$name} {
		color: #{$value} !important;
	}
}

.list-inline-item:not(:last-child) {
	margin-right: 0px;
	margin-bottom: 5px;
}

.rounded {
	border-radius: 10px !important;
}
.rounded-top {
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
}

.small,
small {
	font-size: 90%;
}
