/*================================================
Helper CSS (Section Components)
=================================================*/
.section {
	padding: 100px 0;
	position: relative;
}

.title-heading {
	line-height: 26px;
	.heading {
		font-size: 45px;
		letter-spacing: 1px;
	}
	.para-desc {
		font-size: 18px;
	}
}
.section-title {
	position: relative;
	.main-title {
		font-size: 35px;
		letter-spacing: 0.8px;
	}
	.title {
		letter-spacing: 0.5px;
		font-size: 30px;
	}
	.bar {
		height: 4px;
		width: 85px;
		background: $primary;
		margin: 20px auto 25px;
		position: relative;
		border-radius: 5px;
		overflow: hidden;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 5px;
			background: $light;
			animation-duration: 2s;
			animation-timing-function: linear;
			animation-iteration-count: infinite;
			animation-name: MOVE-BG;
		}
	}
}
@-webkit-keyframes MOVE-BG {
	from {
		-webkit-transform: translateX(0);
	}
	to {
		-webkit-transform: translateX(85px);
	}
}
@keyframes MOVE-BG {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(85px);
	}
}
.para-desc {
	max-width: 600px;
}
.mt-100 {
	margin-top: 100px;
}
.mb-60 {
	margin-bottom: 60px;
}

.mover {
	animation: mover 1s infinite alternate;
}
@keyframes mover {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		transform: translateY(15px);
	}
}

#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: linear-gradient(45deg, $white, $white);
	z-index: 9999999;
	#status {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		.spinner {
			width: 40px;
			height: 40px;
			position: relative;
			margin: 100px auto;
			.double-bounce1,
			.double-bounce2 {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				background-color: $primary;
				opacity: 0.6;
				position: absolute;
				top: 0;
				left: 0;
				animation: sk-bounce 2s infinite ease-in-out;
			}
			.double-bounce2 {
				animation-delay: -1s;
			}
		}
	}
}
@keyframes sk-bounce {
	0%,
	100% {
		transform: scale(0);
	}
	50% {
		transform: scale(1);
	}
}

.social-icon {
	li {
		a {
			color: $dark;
			border: 1px solid $dark;
			display: inline-block;
			height: 32px;
			text-align: center;
			font-size: 15px;
			width: 32px;
			line-height: 30px;
			transition: all 0.4s ease;
			overflow: hidden;
			position: relative;
			margin-bottom: -4px;
			&:hover {
				background-color: $primary;
				border-color: $primary !important;
				color: $white !important;
			}
		}
	}
	&.social {
		li {
			a {
				color: $gray-500;
				border-color: $gray-500;
			}
		}
	}
}

.back-to-top {
	width: 33px !important;
	height: 33px !important;
	line-height: 33px !important;
	color: $muted !important;
	background-color: $gray-400 !important;
	transition: all 0.5s ease !important;
	box-shadow: $shadow !important;
	svg {
		width: 10px !important;
		transition: all 0.5s ease !important;
	}
	&:hover {
		background-color: $primary !important;
		color: $white !important;
		transform: rotate(45deg) !important;
		svg {
			transform: rotate(-45deg) !important;
			fill: $white !important;
		}
	}
}

@media (max-width: 768px) {
	.section {
		padding: 60px 0;
	}
	.title-heading {
		.heading {
			font-size: 35px !important;
		}
		.para-desc {
			font-size: 18px;
		}
	}
	.section-title {
		.main-title {
			font-size: 28px;
		}
		.title {
			font-size: 24px;
		}
	}
	.mt-60 {
		margin-top: 60px;
	}
}
