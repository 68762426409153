/*================================================
Style CSS
=================================================*/
@import "variables";
@import "bootstrap-custom";
@import "components";
@import "general";
@import "helper";
@import "menu";
@import "home";
@import "solution";
@import "video";
@import "latest";
@import "team";
@import "contact";
@import "footer";

li.active > a {
	color: #d81935 !important;
}

a {
	&:hover {
		cursor: pointer;
	}
}

.link {
	a {
		&:hover {
			color: $link !important;
		}
	}
}

.grayed {
	img {
		filter: grayscale(100%);
		&:hover {
			filter: none;
		}
	}
}

.back-to-top {
	line-height: 31px !important;
}
