/*================================================
Team CSS
=================================================*/
.team {
	img {
		height: 200px;
		transition: all 0.5s ease;
	}
	.team-name {
		&:hover {
			color: $primary !important;
		}
	}
	.team-icon {
		position: absolute;
		bottom: 50%;
		transform: translateY(50%);
		right: 0;
		opacity: 0;
		left: 0;
		text-align: center;
		transition: all 0.5s ease;
	}
	&:hover {
		img {
			box-shadow: $shadow;
			opacity: 0.2;
		}
		.team-icon {
			opacity: 1;
		}
	}
}
