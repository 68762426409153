/*================================================
Contact CSS
=================================================*/
.contact-detail {
	.icon {
		img {
			height: 70px;
		}
	}
	.content {
		.title {
			font-size: 20px;
		}
	}
}

.form-group {
	.icons {
		position: absolute;
		top: 0;
		left: 0;
		line-height: 100px;
		font-size: 18px;
	}
}

.map {
	iframe {
		width: 100%;
		height: 400px;
	}
}

@media (min-width: 768px) {
	.map {
		&.map-height-two {
			iframe {
				height: 640px;
			}
		}
	}
}
