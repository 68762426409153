/*================================================
Footer CSS
=================================================*/
.footer {
	background: $secondary;
	padding: 60px 0;
	position: relative;
	color: $gray-500;
	.logo-footer {
		color: $light;
		font-weight: 700;
		font-size: 32px;
		padding: 3px 0;
		letter-spacing: 1px;
	}
	&.footer-bar {
		padding: 30px 0;
		.payment-cards {
			img {
				height: 25px;
			}
		}
	}
}

hr {
	margin-top: 0;
	margin-bottom: 0;
	border: 0;
	border-top: 1px solid #333744;
}
