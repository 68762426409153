/*================================================
Home CSS
=================================================*/
@mixin home-common {
	background-size: cover;
	align-self: center;
	position: relative;
	background-position: center center;
}

.home-center {
	display: table;
	width: 100%;
	height: 100%;
}
.home-desc-center {
	display: table-cell;
	vertical-align: middle;
}

.bg-home {
	height: 100vh;
	@include home-common();
}

.home-dashboard {
	img {
		position: relative;
		margin-top: 5px;
		z-index: 1;
	}
}

@media (max-width: 767px) {
	.bg-home {
		padding: 150px 0;
		height: auto;
	}
}
