/*================================================
Latest CSS
=================================================*/
.blog {
	transition: all 0.3s ease;
	.overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0;
		transition: all 0.5s ease;
	}
	.content {
		h4 {
			line-height: 1.2;
		}
		.title {
			font-size: 20px;
			transition: all 0.5s ease;
			&:hover {
				color: $primary !important;
			}
		}
		.post-meta {
			.like,
			.readmore {
				font-size: 15px;
				transition: all 0.5s ease;
			}
			.readmore {
				&:hover {
					color: $primary !important;
				}
			}
		}
	}
	.author {
		position: absolute;
		top: 5%;
		left: 5%;
		z-index: 1;
		opacity: 0;
		transition: all 0.5s ease;
	}
	&:hover {
		transform: translateY(-10px);
		.overlay {
			opacity: 0.4;
		}
		.author {
			opacity: 1;
		}
	}
}
